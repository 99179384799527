<template>
  <Modal v-model="showPortrayTags" :width="showStationPortray?1400:1000" footer-hide :closable="false" :styles="{top: '40px'}">
    <Row :gutter="16">
      <i-col :span="showStationPortray?16:24">
        <p class="text-center text-14 text-orange"><Icon type="ios-information-circle"></Icon>设置客流标签</p>

        <p class="text-14 m-b-10"><a @click="handleShowPanel(1)">周边常驻画像 ⇊</a></p>
        <transition name="out-in">
          <div v-if="defaultPanel === 1">
            <Row v-for="(item,i) in residentLabel" :key="'f'+i" style="margin-bottom: 15px;">
              <i-col span="2" class="title">{{item.labelName}}</i-col>
              <i-col v-if="item.selectOneFlag"  span="22">
                <RadioGroup v-model="residentBean[item.labelCode]">
                  <Radio :label="-1">不限</Radio>
                  <Radio v-for="(subItem,j) in item.subLabelList" :key="j" :label="subItem.id">{{ subItem.name }}</Radio>
                </RadioGroup>
              </i-col>

              <template v-else>
                <i-col span="1">
                  <Button :type="residentBean[item.labelCode].length>0?'default':'primary'" :style="{color: residentBean[item.labelCode].length>0?'#515a6e':''}" size="small" @click="residentBean[item.labelCode]=[]">不限</Button>
                </i-col>
                <i-col span="21" class="p-l-10">
                  <CheckboxGroup v-model="residentBean[item.labelCode]">
                    <Checkbox v-for="(subItem,j) in item.subLabelList" :key="j"  :label="subItem.id">{{ subItem.name }}</Checkbox>
                  </CheckboxGroup>
                </i-col>
              </template>
            </Row>
          </div>
        </transition>

        <Divider style="margin-bottom: 10px;"/>
        <p class="text-14 m-b-10"><a @click="handleShowPanel(2)">周边游客画像 ⇊</a></p>
        <transition name="out-in">
          <div v-if="defaultPanel === 2">
            <Row v-for="(item,i) in touristLabel" :key="'f'+i" style="margin-bottom: 15px;">
              <i-col span="2" class="title">{{item.labelName}}</i-col>
              <i-col v-if="item.selectOneFlag"  span="22">
                <RadioGroup v-model="touristBean[item.labelCode]">
                  <Radio :label="-1">不限</Radio>
                  <Radio v-for="(subItem,j) in item.subLabelList" :key="j" :label="subItem.id">{{ subItem.name }}</Radio>
                </RadioGroup>
              </i-col>

              <template v-else>
                <i-col span="1">
                  <Button :type="touristBean[item.labelCode].length>0?'default':'primary'" :style="{color: residentBean[item.labelCode].length>0?'#515a6e':''}" size="small" @click="touristBean[item.labelCode]=[]">不限</Button>
                </i-col>
                <i-col span="21" class="p-l-10">
                  <CheckboxGroup v-model="touristBean[item.labelCode]">
                    <Checkbox v-for="(subItem,j) in item.subLabelList" :key="j"  :label="subItem.id">{{ subItem.name }}</Checkbox>
                  </CheckboxGroup>
                </i-col>
              </template>
            </Row>
          </div>
        </transition>

        <Divider style="margin-bottom: 10px;"/>
        <p class="text-14 m-b-10"><a @click="handleShowPanel(3)">周边业态分布 ⇊</a></p>
        <transition name="out-in">
          <div v-if="defaultPanel === 3">
            <CheckboxGroup v-model="checkFormat">
              <Checkbox v-for="(item,i) in formatLabel" :key="'t'+i" :label="item.id">{{ item.name }}</Checkbox>
            </CheckboxGroup>
          </div>
        </transition>

        <Divider style="margin-bottom: 10px;"/>
        <p class="text-14 m-b-10"><a @click="handleShowPanel(4)">周边配套分布 ⇊</a></p>
        <transition name="out-in">
          <div v-if="defaultPanel === 4">
            <CheckboxGroup v-model="checkAssorted">
              <Checkbox v-for="(item,i) in assortedLabel" :key="'a'+i" :label="item.id">{{ item.name }}</Checkbox>
            </CheckboxGroup>
          </div>
        </transition>

        <p class="text-right">
          <Button class="btn-black m-r-10" @click="showPortrayTags=false">取消</Button>
          <Button type="success" class="m-r-5" @click="handleSaveSetting">查询站点</Button>
        </p>

        <div v-show="showStationPortray">
          <Divider dashed ><span class="divider-text">检索结果</span></Divider>
          <p v-for="(item,i) in recommendAssetList" :key="i">
            <span class="title">{{ item }}：</span>
            <span v-for="(subItem,j) in recommendStationList.filter(x=>x.assetName===item)" :key="j">
              {{ subItem.stationName }}、
            </span>
          </p>
          <p class="text-center m-t-20">
            <Button type="warning" size="large" style="width: 120px;" @click="hanldeSubmit">保存设置</Button>
          </p>
        </div>
      </i-col>
      <i-col v-show="showStationPortray" :span="8" class="order-portray-content">
        <p class="text-16">站点标签数据汇总</p>
        <Divider style="margin: 10px 0;"></Divider>
        <OrderPortrayDetail ref="orderPortrayDetail" :defaultOrderDetail="false"/>
      </i-col>
    </Row>
  </Modal>
</template>

<script>
import OrderPortrayDetail from './OrderPortrayDetail'

import {
  getResidentLabelList, getTouristLabelList, getIndustryLabelList, getRecommendStationList
  , getStationPopulation
} from '@/api/dm/sun'

import { countHalfYearAvgDayFlowGbStation } from '@/api/dw/passengerflow'

export default {
  props: {
    allowStationIds: {
      type: Array,
      default: null
    }
  },
  components: {
    OrderPortrayDetail
  },
  data () {
    return {
      showPortrayTags: false,
      defaultPanel: 1,
      recommendStationList: [], // 按结果推荐的站点清单
      recommendAssetList: [], // 按结果推荐的线路清单
      stationFlowTotal: 0,
      stationCount: 0,
      keliu: 0,
      changzhu: 0,
      juzhu: 0,
      gongzuo: 0,
      showStationPortray: false,

      residentLabel: [],
      residentBean: {}, // 周边常驻画像

      touristLabel: [],
      touristBean: {}, // 周边客流画像

      assortedLabel: [],
      checkAssorted: [], // 周边配套分布

      formatLabel: [],
      checkFormat: [] // 周边配套分布
    }
  },
  methods: {
    showModal () {
      this.showPortrayTags = true
      this.showStationPortray = false
      this.recommendStationList = []

      this.initBaseData()
    },
    initBaseData () {
      getResidentLabelList().then(res => {
        this.residentLabel = res
        // 动态声明绑定对象
        const tempResidentBean = {}
        res.forEach(element => {
          Object.defineProperty(tempResidentBean, element.labelCode,
            { value: element.selectOneFlag ? -1 : [], enumerable: true, writable: true, configurable: true }
          )
        })
        this.residentBean = tempResidentBean
      })
      getTouristLabelList().then(res => {
        this.touristLabel = res
        // 动态声明绑定对象
        const tempTouristBean = {}
        res.forEach(element => {
          Object.defineProperty(tempTouristBean, element.labelCode,
            { value: element.selectOneFlag ? -1 : [], enumerable: true, writable: true, configurable: true }
          )
        })
        this.touristBean = tempTouristBean
      })

      // 商业类型(1.配套;2:业态;)
      getIndustryLabelList({ industryType: 1 }).then(res => {
        this.assortedLabel = res
      })
      getIndustryLabelList({ industryType: 2 }).then(res => {
        this.formatLabel = res
      })
    },
    handleSaveSetting () {
      // 重新格式化数据
      const formatResidentBean = {}

      for (const key in this.residentBean) {
        // 如果是数组， 那么需要转换成字符串
        if (this.residentBean[key] instanceof Array) {
          Object.defineProperty(formatResidentBean, key,
            { value: this.residentBean[key].join(','), enumerable: true, writable: true, configurable: true }
          )
        } else {
          Object.defineProperty(formatResidentBean, key,
            { value: this.residentBean[key], enumerable: true, writable: true, configurable: true }
          )
        }
      }

      const formatTouristBean = {}
      for (const key in this.touristBean) {
        // 如果是数组， 那么需要转换成字符串
        if (this.touristBean[key] instanceof Array) {
          Object.defineProperty(formatTouristBean, key,
            { value: this.touristBean[key].join(','), enumerable: true, writable: true, configurable: true }
          )
        } else {
          Object.defineProperty(formatTouristBean, key,
            { value: this.touristBean[key], enumerable: true, writable: true, configurable: true }
          )
        }
      }

      const postData = {
        businessIds: this.checkFormat.join(','),
        facilityIds: this.checkAssorted.join(','),
        residentBean: formatResidentBean,
        stationIds: this.allowStationIds.join(','),
        touristBean: formatTouristBean
      }

      getRecommendStationList(postData).then(res => {
        this.recommendStationList = res

        // 计算站点数量， 需要排除换乘站
        const stationNames = []
        const assetNames = []
        res.forEach(element => {
          if (!stationNames.includes(element.stationName)) {
            stationNames.push(element.stationName)
          }

          if (!assetNames.includes(element.assetName)) {
            assetNames.push(element.assetName)
          }
        })
        this.recommendAssetList = assetNames
        this.stationCount = stationNames.length

        // 获取并显示推荐的站点画像
        const recommendStationIds = res.map(x => x.stationId).join(',')
        this.loadStationPortray(recommendStationIds)
      })
    },
    loadStationPortray (stationIds) {
      // 获取站点客流数据
      var getFlow = countHalfYearAvgDayFlowGbStation({ stationIds: stationIds }).then(res => {
        let flowTotal = 0
        res.forEach(element => {
          flowTotal += element.flowIn
          flowTotal += element.flowTransfer
        })

        this.stationFlowTotal = flowTotal
      })

      const queryModel = {
        personType: 1, // 人口类型(1.常驻;2:客流;)
        stationIds: stationIds
      }
      // 获取站点的人口数据
      var getPopulation = getStationPopulation(queryModel).then(res => {
        this.changzhu = res.find(x => x.name === '常驻').number
        this.juzhu = res.find(x => x.name === '居住').number
        this.gongzuo = res.find(x => x.name === '工作').number
      })

      const queryModel2 = {
        personType: 2, // 人口类型(1.常驻;2:客流;)
        stationIds: stationIds
      }
      var getPopulation2 = getStationPopulation(queryModel2).then(res => {
        this.keliu = res.length > 0 ? res[0].number : 0
      })

      // 执行完全部查询后， 继续执行下一步操作
      Promise.all([getFlow, getPopulation, getPopulation2]).then(() => {
        const moreBean = {
          stationIds: stationIds,
          stationFlowTotal: this.stationFlowTotal,
          stationCount: this.stationCount,
          keliu: this.keliu,
          changzhu: this.changzhu,
          juzhu: this.juzhu,
          gongzuo: this.gongzuo
        }

        this.showStationPortray = true
        this.$nextTick(() => {
          this.$refs.orderPortrayDetail.showDetail(moreBean)
        })
      })
    },
    handleShowPanel (val) {
      this.defaultPanel = (this.defaultPanel === val) ? null : val
    },
    hanldeSubmit () {
      this.showPortrayTags = false
      this.$emit('on-submit', this.recommendStationList.map(x => x.stationId))
    }
  }
}
</script>

<style scoped>
.order-portray-content{
  height: 800px;
  overflow-y: auto;
  border-left: 1px solid #6d737b;
}
</style>
