<template>
    <div>
        <div v-if="isAuth('order_modify_scheme')">
          <h4 class="p-b-5">指定资源位置（默认全选）</h4>
          <Row class="p-b-5">
              <i-col span="24">
                  <div v-for="(position) in positions" :key="position.value" class="p-l-10 p-1-10"
                      :class="checkPositions.includes(position.value)?'workplatform-radio-button-active':'workplatform-radio-button'" @click="changeCheckPositions(position.value)"
                      >{{position.name}}</div>
              </i-col>
          </Row>
        </div>
        <template v-if="audienceConditions">
          <h4 class="p-b-5">受众标签</h4>
          <Row class="p-b-5">
              <i-col span="24">
                <p v-for="(item,index) in checkUserLabels" :key="index">
                  <span class="title">{{item.labelType}}</span>：
                  <span v-for="(checkSub,j) in item.checkGroup" :key="j">
                    {{item.checkGroupName[j]}} <span v-if="j < item.checkGroup.length - 1">、</span>
                  </span>
                </p>

                <p class="p-t-5 p-b-5"><a @click="handleShowUserLabel">设置标签属性</a></p>
              </i-col>
          </Row>
        </template>
        <div v-if="isAuth('order_modify_scheme')">
          <!-- 东郊园区媒介，暂时不支持显示房价和商圈 -->
          <template v-if="query.categoryId!==21">
            <h4 class="p-b-5">房价策略</h4>
            <Row class="p-b-5">
                <i-col span="16" class="p-l-5 p-r-5">
                    <Slider range v-model="housePriceRegion.region" :step="100" :min="housePriceRegion.minPrice" :max="housePriceRegion.maxPrice"
                      @on-change="initCheckedStations" ></Slider>
                </i-col>
                <i-col span="8" class="p-t-10 p-l-5 text-orange">{{housePriceRegion.region[0]}}-{{housePriceRegion.region[1]}}元</i-col>
            </Row>

            <Tabs size="small">
              <TabPane label="客流画像" v-if="showNewPortray">
                <div class="text-center p-t-20">
                  <i-button type="primary" icon="ios-people" @click="handleSettingPortray">设置客流标签</i-button>
                </div>
              </TabPane>
              <TabPane label="商圈策略">
                <Row class="p-t-5">
                    <i-col span="24">
                        <i-input type="text" size="small" clearable v-model="GenerationCondition.keyword"
                          @on-change="handleChangeKeyword"
                          placeholder="输入关键字检索车站。如：超市/电影院。" ></i-input>
                        <p class="remark">关键字技巧：A+B：包含关键字A或关键字B的车站；A-B：包含关键字A且不包含关键字B的车站；A B：包含关键字A且包含关键字B的车站。</p>
                    </i-col>
                </Row>
                <Row>
                    <i-col span="16">
                        <Slider v-model="GenerationCondition.distance" :max="1000" :step="50" @on-change="AdaptationRange" ></Slider>
                    </i-col>
                    <i-col span="8" class="p-t-10 p-l-5">适配范围( <span class="text-orange">{{ GenerationCondition.distance }}</span> 米)</i-col>
                </Row>
              </TabPane>
              <TabPane label="特色站点">
                <Row class="p-t-5">
                    <i-col span="24">
                        <i-input type="text" size="small" clearable v-model="GenerationCondition.keyword"
                            style="width:250px" placeholder="输入关键字：商家、机构、站点名称等" ></i-input>

                        <i-button size="small" type="primary" class="m-l-5" :disabled="GenerationCondition.keyword.length===0" icon="ios-search" @click="handleSearchStation">搜索</i-button>
                    </i-col>
                </Row>
              </TabPane>
            </Tabs>
          </template>

          <Divider dashed style="margin: 0;" class="p-l-20 p-r-20">
            <a style="font-size: xx-small" @click="handleChangeCondition">
                <span v-if="!showMoreCondition"><Icon type="md-arrow-round-down" />点击显示筛选条件<Icon type="md-arrow-round-down" /></span>
                <span v-else><Icon type="md-arrow-round-up" />点击收起筛选条件<Icon type="md-arrow-round-up" /></span>
            </a>
          </Divider>

          <Row v-show="showMoreCondition" v-for="level in levels" :key="level.value">
              <i-col span="24" >
                  <label class="title">指定包含 <strong class="text-orange">{{level.name}}</strong> 级站台范围
                      <Checkbox v-model="level.isAllChecked" @on-change="checkAllStation(level.value,level.stations)">
                          <strong class="text-href">全选</strong>
                      </Checkbox>
                  </label>
                  <div>
                    <div v-for="(station,i) in level.stations" :key="'s'+i" class="p-l-10 p-1-10 m-b-2" @click="changeCheckStations(station.value)"
                      :class="(checkStationIds.includes(station.value)&&station.hasProduct)?'workplatform-radio-button-active':'workplatform-radio-button'">{{station.name}}</div>

                      <!-- <Button v-for="station in level.stations" :key="station.value" :disabled="!station.hasProduct" :type="(checkStationIds.includes(station.value)&&station.hasProduct)?'primary':'text'"  @click="changeCheckStations(station.value)"  size="small" style="margin:1px">{{station.name}}</Button> -->
                  </div>
              </i-col>
          </Row>

          <Row :gutter="8" class="p-t-5">
              <i-col class="p-b-5" span="12" v-for="(sku,index) in skus" :key="index">
                  <label><strong>{{sku.stationLevelName}}级：</strong> (余量 <strong class="text-orange">{{sku.max}}</strong>)</label>
                  <InputNumber size="small" style="width:100px" :max="sku.max" :min="0" v-model="sku.quantity" @on-change="loadProductDiscount"></InputNumber>
              </i-col>
          </Row>
          <template v-if="isRatio">
            <Divider style="margin: 5px 0;" />
            <Row>
                <i-col span="24">
                    采购总量：
                    <InputNumber v-model="purchaseNum" size="small" :min="0" :max="10000" style="width:120px"
                      placeholder="请输入采购总量" @on-change="handleChangePurchaseNum"></InputNumber>
                    <span class="remark m-l-5" v-if="!isSubmit">(折扣：<span class="text-orange">{{productDiscount}}</span>)</span>
                </i-col>
            </Row>
          </template>
         </div>

        <Row :gutter="8" class="p-t-10">
            <!-- <i-col span="9">
              <Button class="btn-black" size="small" long  @click="handleShowResource">地图显示点位余量</Button>
            </i-col> -->
            <i-col span="12">
              <Button type="success" size="small" long  @click="handleShowListPurchase">自定义资源采购</Button>
            </i-col>
            <i-col span="12" v-if="isAuth('order_modify_scheme')">
              <Button type="success" size="small" long :disabled="isSubmit" :loading="spinShow"  @click="handleGenerationResources">自动生成点位方案</Button>
            </i-col>
        </Row>
        <p class="p-t-5"><a @click="handleShowResource"><Icon type="md-refresh" />刷新地图点位余量</a></p>

        <ListPurchase v-if="query.allowLines.length>0" ref="customResource" :assets="query.allowLines" :orderId="query.orderId"
          :schedules="query.orderScheduleList" :checkSchedules="query.schedules" :categoryId="query.categoryId"
          :productId="query.productId" :stationId="clickGeoMapStationId"
          :allowProductList="query.allowProductList" @updateData="ReloadStocks"></ListPurchase>

        <ListRecommend ref="listRecommend" @handlePlan1="beginGenerationResources"  @handlePlan2="beginAddResources"/>

        <ListUserLabel ref="listUserLabel" @handleSaveUserLabel="changeUserLabels"/>

        <StationSearch ref="stationSearch" @on-change-stations="handleChangeStation"/>

        <ListPortray ref="listPortray" :allowStationIds="allowStationIds" @on-submit="handleAutoCheckStations"/>

    </div>
</template>

<script>
import ListPurchase from '../common/ListPurchase'
import ListRecommend from '../common/ListRecommend'
import ListUserLabel from '../common/ListUserLabel'
import StationSearch from '../common/StationSearch'
import ListPortray from '../common/ListPortray'

import { stockQuantitys, getStationIdsByCondition, getSkuRatio, getDiscount } from '@/api/product/product'
import { getProductAndSkuList } from '@/api/product/productsku'
import { getStationProduct, getStationResourceQuantity } from '@/api/product/productresource'
import { getPositions } from '@/api/product/resource'
import { adddtProductSkus, addOrderPlan } from '@/api/order/orderitem'
import { applyPlan } from '@/api/dw/recommend'
import { getCityHousePriceRegion } from '@/api/dmp/building'
import { getCitys } from '@/api/product/asset'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    ListPurchase, ListRecommend, ListUserLabel, StationSearch, ListPortray
  },
  data () {
    return {
      query: {
        orderId: -1,
        assetIds: '', // 资产主键
        categoryId: null, // 产品类别主键
        productId: null,
        resourcetypeIds: '', // 资源类型， 标准灯箱合并了12封和18封灯箱
        schedules: '', // 订单采购档期

        orderScheduleList: [], // 可用的全部档期
        allowProductList: [], // 全部允许的产品清单
        allowLines: [] // 全部的线路
      },

      spinShow: false,
      skus: [], // 可采购的产品类型（级别）
      purchaseNum: 0, // 按量采购数量

      levels: [],
      positions: [],
      checkPositions: [],
      allowStationIds: [], // 当前条件下所有被允许的站点清单
      checkStationIds: [], // 选中的站点集合
      checkAllStatus: [],
      GenerationCondition: { // 生成资源的条件
        keyword: '',
        distance: 500
      },
      recommend: this.$store.getters.token.publisherSetting.recommend, // 是否开启资源推荐
      audienceConditions: this.$store.getters.token.publisherSetting.audienceConditions, // 是否开启受众标签
      showNewPortray: this.$store.getters.token.publisherSetting.stationPortray === 2, // 是否开启受众标签
      isRatio: false,
      productDiscount: 0, // 按质制价折扣值
      showMoreCondition: false,
      productskuList: [], // 产品清单

      housePriceRegion: { // 房价策略
        maxPrice: 0,
        minPrice: 0,
        region: [0, 0]
      },

      checkUserLabels: [] // 受众标签
    }
  },
  mounted () {
    // 获取城市房价区间
    getCitys({ publisherId: this.$store.getters.token.userInfo.publisherId }).then(citys => {
      getCityHousePriceRegion({ cityCode: citys[0].cityCode }).then(res => {
        this.housePriceRegion.maxPrice = res.maxPrice
        this.housePriceRegion.minPrice = res.minPrice
        this.housePriceRegion.region = [res.minPrice, res.maxPrice]
      })
    })
  },
  methods: {
    initPageData (searchBean) {
      this.query.orderId = searchBean.orderId
      this.query.assetIds = JSON.stringify(searchBean.assetIds) // 资产主键
      this.query.categoryId = searchBean.categoryId // 产品类别主键
      this.query.productId = searchBean.productId
      this.query.resourcetypeIds = searchBean.resourcetypeIds
      this.query.schedules = JSON.stringify(searchBean.schedules) // 订单采购档期

      this.query.allowProductList = searchBean.allowProductList
      this.query.allowLines = searchBean.allowLines
      this.query.orderScheduleList = searchBean.orderScheduleList

      this.defaultProduct = {
        productId: this.query.productId,

        startDate: searchBean.schedules[0].startDate,
        endDate: searchBean.schedules[0].endDate
      }
      this.SeachResource()
    },
    SeachResource () {
      const main = {
        assetIds: this.query.assetIds,
        categoryId: this.query.categoryId,
        pageNumber: 1,
        pageSize: 999,
        orderId: this.query.orderId,
        productId: this.query.productId
      }

      getProductAndSkuList(main).then(res => {
        // 初始化所有字段数据
        this.levels = []
        this.checkStationIds = []
        this.checkPositions = []
        this.checkAllStatus = []
        this.GenerationCondition.keyword = ''
        this.GenerationCondition.distance = 500

        // 初始化查询条件
        this.skus = res.list.map(x => {
          x.quantity = 0
          x.price = toMoney(x.usePrice)
          x.ratio = 0
          return x
        })

        this.loadSkuRation()
      })
    },
    loadSkuRation () {
      const postData = { productId: this.query.productId }

      getSkuRatio(postData).then(res => {
        let flag = 0
        let skuBean
        res.forEach(element => {
          skuBean = this.skus.find(x => x.productskuId === element.productSkuId)
          if (skuBean) {
            skuBean.ratio = element.ratio
          }
          flag += element.ratio
        })

        this.isRatio = flag > 0
        this.getResourcePostions()
      })
    },
    handleChangeCondition () {
      this.showMoreCondition = !this.showMoreCondition
    },
    AdaptationRange (val) { // 查询距离改变
      if (this.GenerationCondition.keyword.trim().length > 0) {
        this.initCheckedStations()
      }
    },
    getResourcePostions () { // 获取可用的资源位置
      const queryData = {
        productId: this.query.productId,
        resourcetypeIds: this.query.resourcetypeIds,
        assetIds: this.query.assetIds
      }

      getPositions(queryData).then(res => {
        this.positions = res
        res.forEach(x => {
          this.checkPositions.push(x.value)
        })
      }).then(() => {
        this.getResourceStation()
      })
    },
    getResourceStation () { // 获取可用的资源站点列表
      const data = {
        assetIds: this.query.assetIds,
        productId: this.query.productId,
        resourcetypeIds: this.query.resourcetypeIds,
        excludeOrderId: this.query.orderId,
        schedules: this.query.schedules
      }
      getStationProduct(data).then(res => {
        this.levels = []
        this.checkAllStatus = []
        res.forEach(asset => {
          // 循环添加级别和级别下面所属的站点集合
          if (asset.stationGroupLevelBeanList !== null && asset.stationGroupLevelBeanList.length > 0) {
            asset.stationGroupLevelBeanList.forEach(item => {
              if (this.levels.filter(level => level.value === item.stationLevel).length === 0) {
                this.levels.push({
                  isAllChecked: false,
                  name: item.stationLevelName,
                  value: item.stationLevel,
                  stations: item.stationBeanList.map(sta => {
                    return {
                      value: sta.stationId,
                      name: sta.stationName,
                      assetName: asset.assetName,
                      stationName: sta.stationName,
                      hasProduct: sta.hasProduct
                    }
                  })
                })
                this.checkAllStatus.push({ name: item.stationLevel, value: false })
              } else {
                const _level = this.levels.filter(level => level.value === item.stationLevel)[0]
                let station = null
                item.stationBeanList.map(sta => {
                  // 验证时候已经存在相同的名称， 换乘站
                  station = _level.stations.find(x => x.stationName === sta.stationName)
                  if (station) {
                    // 修改原有站点名称
                    station.name = station.stationName + '(' + station.assetName + ')'
                    // 添加新的站点名称
                    _level.stations.push({ value: sta.stationId, name: sta.stationName + '(' + asset.assetName + ')', stationName: sta.stationName, hasProduct: sta.hasProduct, assetName: asset.assetName })
                  } else {
                    _level.stations.push({ value: sta.stationId, name: sta.stationName, stationName: sta.stationName, hasProduct: sta.hasProduct, assetName: asset.assetName })
                  }
                })
              }
            })
          }
        })

        // 获取当前线路下所有可用的站点ID
        this.allowStationIds = []
        this.levels.forEach(element => {
          this.allowStationIds.push(...element.stations.map(x => x.value))
        })
      }).then(() => {
        this.ReloadStocks()
      })
    },
    changeCheckPositions (name) { // 位置选中发生更改
      if (this.checkPositions.includes(name)) {
        const index = this.checkPositions.indexOf(name)
        this.checkPositions.splice(index, 1)
      } else {
        this.checkPositions.push(name)
      }
      this.ReloadStocks()
    },
    changeUserLabels (data) {
      this.checkUserLabels = []
      data.forEach(element => {
        this.checkUserLabels.push({
          labelType: element.labelType,
          checkGroup: element.checkGroup,
          checkGroupName: element.checkGroupName
        })
      })
      this.initCheckedStations()
    },
    changeCheckStations (stationId) { // 站台选中发生更改
      if (this.checkStationIds.includes(stationId)) {
        const index = this.checkStationIds.findIndex(x => x === stationId)
        this.checkStationIds.splice(index, 1)
      } else {
        this.checkStationIds.push(stationId)
      }
      this.ReloadStocks(false, true)
    },
    checkAllStation (levelValue, levelStations) { // 全选可用的站点
      const _this = this
      const checkStatus = _this.checkAllStatus.filter(x => x.name === levelValue)[0]
      if (checkStatus.value) {
        levelStations.forEach(checkedSta => {
          const index = _this.checkStationIds.indexOf(checkedSta.value)
          if (index > -1) { _this.checkStationIds.splice(index, 1) }
        })
      } else {
        levelStations.forEach(checkedSta => {
          if (_this.checkStationIds.indexOf(checkedSta.value) === -1 && checkedSta.hasProduct) {
            _this.checkStationIds.push(checkedSta.value)
          }
        })
      }
      checkStatus.value = !checkStatus.value

      this.ReloadStocks(false, true)
    },
    initCheckedStations () { // 加载默认选中站台
      this.checkStationIds = []
      const data = {
        assetIds: this.query.assetIds,
        orderId: this.query.orderId,
        conditions: JSON.stringify(this.condition)
      }

      getStationIdsByCondition(data).then(res => {
        res.forEach(checkedSta => {
          // 判断当前站点是否在可用的清单内
          if (this.checkExsitStation(checkedSta)) {
            this.checkStationIds.push(checkedSta)
          }
        })
        // 地图闪烁站点
        const params = {
          data: this.checkStationIds,
          checkedSchedules: JSON.parse(this.query.schedules),
          assetIds: JSON.parse(this.query.assetIds),
          condition: this.condition
        }
        this.$emit('on-updated-map', 'flicker', params)
      }).then(() => {
        this.ReloadStocks(true)
      })
    },
    checkExsitStation (stationId) {
      let flag = false
      let currentStationStatus
      for (let index = 0; index < this.levels.length; index++) {
        currentStationStatus = this.levels[index].stations.find(x => x.value === stationId)

        if (currentStationStatus && currentStationStatus.hasProduct) {
          flag = true
          break
        }
      }
      return flag
    },
    ReloadStocks (isFlicker = false, showWarningStation = false) {
      const StockQuery = {
        assetIds: this.query.assetIds,
        orderId: this.query.orderId,
        productId: this.query.productId,
        resourcetypeIds: this.query.resourcetypeIds,
        schedules: this.query.schedules,
        positions: JSON.stringify(this.checkPositions),
        stationIds: JSON.stringify(this.checkStationIds)
      }
      stockQuantitys(StockQuery).then(res => {
        res.forEach(stock => {
          const index = this.skus.findIndex(s => s.productskuId === stock.productSkuId)
          if (index !== -1) {
            let quantity = 0
            if (this.skus[index] && this.skus[index].quantity <= stock.quantity) {
              quantity = this.skus[index].quantity
            }
            this.skus.splice(index, 1, Object.assign({}, this.skus[index], { max: stock.quantity, quantity: quantity }))
          }
        })
      })

      // 更新地图显示站点和线路
      if (!isFlicker) {
        this.handleShowResource(showWarningStation)
      }
    },
    handleGenerationResources () {
      if (this.recommend) {
        const postData = {
          orderId: this.query.orderId,
          productId: this.query.productId,
          resourcetypeIds: this.query.resourcetypeIds,
          productskus: this.productsku,
          schedules: this.query.schedules,
          assetIds: this.query.assetIds,
          conditions: JSON.stringify(this.condition),
          positions: JSON.stringify(this.checkPositions),
          stationIds: JSON.stringify(this.checkStationIds)
        }

        this.$refs.listRecommend.initData(postData)
      } else {
        this.beginGenerationResources()
      }
    },
    beginGenerationResources () {
      this.spinShow = true

      const addResourceBean = {
        orderId: this.query.orderId, // 采购ID
        assetIds: this.query.assetIds, // 采购的产品ID
        productskus: this.productsku, // 采购的数量集合
        stationIds: JSON.stringify(this.checkStationIds), // 站台集合
        positions: JSON.stringify(this.checkPositions), // 资源位置
        keyword: this.GenerationCondition.keyword,
        distance: this.GenerationCondition.distance,
        schedules: this.query.schedules,
        conditions: JSON.stringify(this.condition)
      }

      adddtProductSkus(addResourceBean).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            desc: '添加资源成功,请在页面右侧【采购清单】中查看购买详情！'
          })

          // 默认选中编辑状态
          this.$emit('on-update-order', this.defaultProduct)

          // 刷新可用生成数量
          this.ReloadStocks(false)
          this.skus.forEach(element => {
            element.quantity = 0
          })
          this.purchaseNum = 0
        }
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    beginAddResources (planId) {
      this.spinShow = true
      const postData = {
        orderId: this.query.orderId,
        planId: planId,
        schedules: this.query.schedules,
        conditions: JSON.stringify(this.condition)
      }

      addOrderPlan(postData).then(res => {
        this.$Notice.success({
          desc: '添加资源成功,请在页面右侧【采购清单】中查看购买详情！'
        })

        // 默认选中编辑状态
        this.$emit('on-update-order', this.defaultProduct)

        // 标识推荐为应用
        applyPlan({ planId: planId })

        // 刷新可用生成数量
        this.ReloadStocks(false)
        this.skus.forEach(element => {
          element.quantity = 0
        })
        this.purchaseNum = 0
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    handleShowResource (showWarningStation) {
      // 查询当前订单已采购资源
      const searchResourceCondition = {
        excludeOrderId: this.query.orderId,
        assetIds: this.query.assetIds,
        productId: this.query.productId,
        resourcetypeIds: this.query.resourcetypeIds,
        schedules: this.query.schedules
      }

      getStationResourceQuantity(searchResourceCondition).then(res => {
        const params = {
          data: res,
          checkedSchedules: JSON.parse(this.query.schedules),
          assetIds: JSON.parse(this.query.assetIds),
          condition: this.condition,
          warningStations: showWarningStation ? this.checkStationIds : []
        }

        this.$emit('on-updated-map', 'full', params)
      })
    },
    handleShowListPurchase () {
      this.$refs.customResource.showModal()
    },
    handleShowUserLabel () {
      this.$refs.listUserLabel.showModal()
    },
    reload () {
      this.ReloadStocks(this.mapType === 'purchase')
    },
    loadProductDiscount () {
      if (this.isRatio && !this.isSubmit) { // 如果是按照比例采购，那么现实价格折扣信息
        const postData = {
          productId: this.query.productId,
          productskus: this.productsku
        }

        getDiscount(postData).then(res => {
          this.productDiscount = res
        })

        this.purchaseNum = 0
        this.skus.forEach(element => {
          this.purchaseNum += element.quantity
        })
      }
    },
    handleChangePurchaseNum () {
      let purchaseQuantity = 0
      this.skus.forEach(element => {
        if (this.purchaseNum > 0) {
          purchaseQuantity = parseInt(this.purchaseNum * element.ratio * 0.1)
          element.quantity = purchaseQuantity < element.max ? purchaseQuantity : element.max
        } else {
          element.quantity = 0
        }
      })
      this.loadProductDiscount()
    },
    handleChangeKeyword () {
      this.showMoreCondition = (this.GenerationCondition.keyword !== '')

      this.initCheckedStations()
    },
    handleSearchStation () {
      if (this.GenerationCondition.keyword.length > 0) {
        this.$refs.stationSearch.initData(this.GenerationCondition.keyword,
          this.query.productId, JSON.parse(this.query.assetIds))
      }
    },
    handleChangeStation (checkStations) {
      this.checkStationIds = []
      checkStations.forEach(checkedSta => {
        // 判断当前站点是否在可用的清单内
        if (this.checkExsitStation(checkedSta)) {
          this.checkStationIds.push(checkedSta)
        }
      })

      // 地图闪烁站点
      const params = {
        data: checkStations,
        checkedSchedules: JSON.parse(this.query.schedules),
        assetIds: JSON.parse(this.query.assetIds),
        condition: this.condition
      }
      this.$emit('on-updated-map', 'flicker', params)

      this.ReloadStocks(true)
    },
    handleSettingPortray () {
      this.$refs.listPortray.showModal()
    },
    handleAutoCheckStations (stationIds) { // 根据选中的客流标签，自动过滤站点
      this.checkStationIds = stationIds
      this.ReloadStocks(false, true)
    }
  },
  computed: {
    isSubmit: function () {
      let total = 0
      this.skus.forEach(element => {
        total += element.quantity
      })

      return total === 0
    },
    condition: function () {
      let targetJson = []
      this.checkUserLabels.forEach(element => {
        targetJson = targetJson.concat(element.checkGroup)
      })

      return {
        distance: this.GenerationCondition.distance,
        keywords: this.GenerationCondition.keyword,
        target: targetJson,
        minHousePrice: this.houseMinPrice,
        maxHousePrice: this.houseMaxPrice
      }
    },
    houseMinPrice () {
      if (this.housePriceRegion.region[0] === this.housePriceRegion.minPrice && this.housePriceRegion.region[1] === this.housePriceRegion.maxPrice) {
        return null
      } else {
        return this.housePriceRegion.region[0]
      }
    },
    houseMaxPrice () {
      if (this.housePriceRegion.region[0] === this.housePriceRegion.minPrice && this.housePriceRegion.region[1] === this.housePriceRegion.maxPrice) {
        return null
      } else {
        return this.housePriceRegion.region[1]
      }
    },
    mapType () {
      return this.$store.state.order.mapType
    },
    productsku () {
      const productSkuList = this.skus.map(sku => {
        return {
          productskuId: sku.productskuId,
          quantity: (sku.quantity === null) ? 0 : sku.quantity
        }
      })

      return JSON.stringify(productSkuList)
    },
    clickGeoMapPurchase () {
      return this.$store.state.order.clickGeoMapPurchase
    },
    clickGeoMapStationId () {
      return this.$store.state.order.clickGetMapStationId
    }
  },
  watch: {
    clickGeoMapPurchase (val) {
      this.handleShowListPurchase()
    }
  }
}
</script>
