<template>
    <Modal v-model="showStationResult" width="1000" title="特色站点检索" footer-hide :mask-closable="false">
        <Row>
            <i-col span="3"><label class="title">查询关键字</label></i-col>
            <i-col span="8">
                <Input size="small" v-model="query.keywords" :search="query.keywords.length>0" enter-button="搜索"
                  @on-search="handleSearch" placeholder="输入关键字：商家、机构、站点名称等" />
            </i-col>
            <i-col span="8" class="p-l-20">
                <RadioGroup v-model="query.keyType" @on-change="handleSearch">
                    <Radio :label="0">全部</Radio>
                    <Radio :label="1">站点</Radio>
                    <Radio :label="2">商家</Radio>
                </RadioGroup>
            </i-col>
            <i-col span="5" class="text-right">
              <i-button type="success" @click="handleSubmit">确认设置</i-button>
            </i-col>
        </Row>
        <Row>
            <i-col span="3" class="p-t-5"><label class="title">搜索半径</label></i-col>
            <i-col span="8">
                <Slider  v-model="query.distance"
                    :step="50"
                    :max="1000"
                    show-tip="never"
                    @on-change="handleSearch"
                ></Slider>
            </i-col>
            <i-col span="3" class="p-t-5">
                <span class="text-orange m-l-10">{{ query.distance }} 米</span>
            </i-col>
        </Row>
        <Row v-if="currentCheckAssets.length>0">
            <i-col span="3"><label class="title">已选站点</label></i-col>
            <i-col span="21">
                <p v-for="(asset,index) in currentCheckAssets" :key="index">
                  {{asset.assetName}}：
                  <Tag color="gray" v-for="checkStation in currentCheckStaions.filter(x=>x.assetId===asset.assetId)" :key="checkStation.stationId"
                    closable  @on-close="handleRemoveStation(checkStation.stationId, true)">
                    {{checkStation.stationName}}
                  </Tag>
                </p>
            </i-col>
        </Row>

        <Row class="p-b-5 p-t-10">
            <i-col span="3"><label class="title">查询结果</label></i-col>
            <i-col span="21">
                <a v-for="(asset,index) in searchResultGroupAsset" :key="index"
                  @click="handleGroupAsset(asset.assetId)" class="m-r-10" :class="chooseGroupAsset===asset.assetId?'choose-active':''">
                  {{asset.assetName}}({{asset.quantity}})
                </a>
            </i-col>
        </Row>
        <Table id="station_table" size="small"
            ref="tableSelection"
            :loading="loading"
            stripe
            :columns="searchColumns"
            :data="searchTableData"
            @on-selection-change="selectionChange"
            @on-sort-change="handleTableSort"
        ></Table>

        <div class="paging_style">
          <Page  size="small"
          :current="query.pageNumber"
          :total="totalRow"
          :page-size="query.pageSize"
          @on-change="handlePageChange"
          show-total
          />
        </div>

    </Modal>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { toMoneyNoPrefix, toNumber } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        distance: 500,
        keywords: '',
        productId: null,
        assetIds: [],
        dynamicLevel: 0,
        keyType: 0, // 0:全部;1:站点,2:商家;
        indexes: [
          { index: '11', start: 0, end: 100 },
          { index: '12', start: 0, end: 100 },
          { index: '13', start: 0, end: 100 },
          { index: '21', start: 0, end: 100 },
          { index: '22', start: 0, end: 100 },
          { index: '23', start: 0, end: 100 },
          { index: '31', start: 0, end: 100 }
        ],
        orderByStr: 'number',
        pageSize: 10,
        pageNumber: 1
      },

      showStationResult: false,
      loading: false,
      searchColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '站名', width: 150, key: 'stationName' },
        { title: '线路', width: 130, key: 'assetName' },
        {
          title: '品牌(个)',
          key: 'interBrand',
          width: 120,
          align: 'center',
          sortable: 'custom',
          render: (h, params) => {
            return h('span', params.row.domesticBrand + params.row.interBrand)
          }
        },
        {
          title: '房价(元)',
          key: 'housePrice',
          sortable: 'custom',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return h('span', params.row.housePrice === 0 ? '-' : toMoneyNoPrefix(params.row.housePrice))
          }
        },
        {
          title: '客单价(元)',
          key: 'consumePrice',
          sortable: 'custom',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('span', params.row.consumePrice === 0 ? '-' : toMoneyNoPrefix(params.row.consumePrice))
          }
        },
        {
          title: '进出站月客流',
          key: 'flowNumber',
          sortable: 'custom',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('span', params.row.flowNumber === 0 ? '-' : toNumber(params.row.flowNumber))
          }
        },
        {
          title: '相关度',
          key: 'rank',
          width: 100,
          sortable: 'custom',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.rank + '%')
          }
        }
      ],
      searchTableData: [],
      totalRow: 0,
      defaultAssetIds: [], // 默认全部的查询线路集合
      searchResultGroupAsset: [], // 线路分组
      chooseGroupAsset: '', // 选中的线路分组

      currentCheckStaions: [], // 当前选中的全部站点
      currentCheckAssets: [] // 当前选中的站点线路分组
    }
  },
  methods: {
    initData (keyword, productId, assetIds) {
      this.showStationResult = true
      this.query.keywords = keyword
      this.query.productId = productId
      this.query.pageNumber = 1
      this.query.assetIds = assetIds
      this.query.orderByStr = 'number'
      this.query.orderByType = null
      this.defaultAssetIds = assetIds
      this.chooseGroupAsset = ''
      this.currentCheckStaions = []
      this.currentCheckAssets = []

      // 获取站点数据汇总
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/condition/getassetquantity',
        this.query,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        if (res.data.errcode) {
          this.$Notice.error({ title: '服务器请求错误', desc: res.data.errmsg })
        } else {
          this.searchResultGroupAsset = res.data
          this.loadSearchResult()
        }
      })
    },
    loadSearchResult () {
      // 获取查询结果
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/condition/getsearchresultpage',
        this.query,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        if (res.data.errcode) {
          this.$Notice.error({ title: '服务器请求错误', desc: res.data.errmsg })
        } else {
          res.data.list.forEach(item => {
            if (this.currentCheckStaions.some(x => x.stationId === item.stationId)) {
              item._checked = true
            }
          })

          this.searchTableData = res.data.list
          this.totalRow = res.data.totalRow
        }
      })
    },
    handleSearch () {
      if (this.query.keywords.length > 0) {
        this.initData(this.query.keywords, this.query.productId, this.query.assetIds)
      }
    },
    handleGroupAsset (assetId) {
      if (assetId === '' || this.chooseGroupAsset === assetId) {
        this.chooseGroupAsset = ''
        this.query.assetIds = this.defaultAssetIds
      } else {
        this.chooseGroupAsset = assetId
        this.query.assetIds = [assetId]
      }

      this.query.pageNumber = 1
      this.loadSearchResult()
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.loadSearchResult()
    },
    handleTableSort (params) {
      this.query.pageNumber = 1
      if (params.order === 'normal') { // 取消排序
        this.query.orderByStr = 'number'
        this.query.orderByType = null
      } else {
        this.query.orderByStr = params.key
        this.query.orderByType = params.order === 'asc' ? 1 : 0
      }
      this.loadSearchResult()
    },
    selectionChange (selection) {
      // 循环当前表格内的所有列
      this.searchTableData.forEach(column => {
        if (!selection.find(x => x.stationId === column.stationId)) {
          this.handleRemoveStation(column.stationId)
        }
      })

      // 如果当前选中站点集合中没有包含选中的站点, 那么添加改站点
      selection.forEach(element => {
        if (!this.currentCheckStaions.find(x => x.stationId === element.stationId)) {
          this.currentCheckStaions.push({
            assetId: element.assetId,
            assetName: element.assetName,
            stationId: element.stationId,
            stationName: element.stationName
          })
        }
      })

      this.groupCheckStationAssets()
    },
    groupCheckStationAssets () {
      this.currentCheckAssets = []

      this.currentCheckStaions.forEach(element => {
        if (!this.currentCheckAssets.find(x => x.assetId === element.assetId)) {
          this.currentCheckAssets.push({
            assetId: element.assetId,
            assetName: element.assetName
          })
        }
      })
    },
    handleRemoveStation (stationId, isTagClick = false) {
      const stationIndex = this.currentCheckStaions.findIndex(x => x.stationId === stationId)

      if (stationIndex > -1) {
        this.currentCheckStaions.splice(stationIndex, 1)
      }

      if (isTagClick) { // 如果是Tag标签点击移除，需要在表格中移除掉该数据
        const currentTableData = this.$refs.tableSelection.$refs.tbody.objData

        for (let index = 0; index < 10; index++) {
          if (currentTableData[index]) {
            currentTableData[index]._isChecked = this.currentCheckStaions.some(x => x.stationId === currentTableData[index].stationId)
          }
        }

        this.groupCheckStationAssets()
      }
    },
    handleSubmit () {
      this.$emit('on-change-stations', this.currentCheckStaions.map(x => x.stationId))
      this.showStationResult = false
    }
  }
}
</script>
