<template>
  <div>
    <Row :gutter="8" class="m-t-5 m-b-10">
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">日均总客运量</p>
          <Row>
            <i-col span="16" class="text-16"><b>{{formatNumber(detailBean.stationFlowTotal)}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">人次</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">车站数量</p>
          <Row>
            <i-col span="16" class="text-16"><b>{{formatNumber(detailBean.stationCount)}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">个</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            月均游客量
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">月均游客</span>基于手机定位和信令数据，计算进入该区域并停留一定时长（提出过境人群，停留时长根据统计范围大小有区别）得到的单月实际到访人次。</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="14" class="text-16"><b>{{detailBean.keliu}}</b></i-col>
            <i-col span="10" class="text-right text-gray" style="padding-top: 4px;">万人/次</i-col>
          </Row>
        </div>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-t-5 m-b-10">
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            常驻人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">常驻人口</span>居住人口+工作人口-重复</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{detailBean.changzhu}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            居住人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">居住人口</span>时间：晚9点-早7点（周一至周五）&早7点-晚8点（周六至周日）小区内同一台设备一个月内出现10次以上或者出现次数最多的点位</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{detailBean.juzhu}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="8">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            工作人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">工作人口</span>时间：早8点-晚7点（周一至周五）写字楼或园区内同一台设备一个月内出现10次以上或者出现次数最多的点位</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{detailBean.gongzuo}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
    </Row>

    <p class="m-b-10">
      <span class="text-16">常驻人口标签</span><span class="text-darkgray"> （单位:%）</span>
    </p>

    <!-- 标签一 -->
    <div class="m-b-20">
      <div class="station-new-tag-title">性别</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_sex" :key="j" class="station-new-tag-content" :style="{'width': content.value + '%', 'background-color': tabColor1[j]}">
          {{ content.name }} {{ content.value }}
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">婚姻</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_marriage" :key="j" class="station-new-tag-content" :style="{'width': content.value + '%', 'background-color': tabColor1[j]}">
          {{ content.name }} {{ content.value }}
        </div>
      </div>
    </div>

    <!-- 标签二 -->
    <div class="m-b-20">
      <div class="station-new-tag-title">年龄</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_age" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_age.length +j]}">
            {{ formatTagValue(content.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">收入</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_income" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_income.length +j]}">
            {{ formatTagValue(content.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">消费</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_consume" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_consume.length +j]}">
            {{ formatTagValue(content.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">教育</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_education" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_education.length +j]}">
            {{ formatTagValue(content.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="m-b-20">
      <div class="station-new-tag-title">出行</div>
      <div class="station-new-tag-process">
        <div v-for="(content,j) in tabArray_travel" :key="j" class="inline" :style="{'width': content.value + '%'}">
          <p>
            <Tooltip v-if="formatTagName(content.name, content.value).indexOf('.')>0" :content="content.name" placement="top-end">
              <span class="text-darkgray">{{ formatTagName(content.name, content.value) }}</span>
            </Tooltip>
            <span v-else class="text-darkgray">{{ content.name }}</span>
          </p>
          <div class="station-new-tag-content" :style="{'width': '100%', 'background-color': tabColor2[6-tabArray_travel.length +j]}">
            {{ formatTagValue(content.value) }}
          </div>
        </div>
      </div>
    </div>

    <!-- 标签三 -->
    <!-- <p class="text-14 text-darkgray" style="padding-left: 12px;margin-bottom: 10px;">职业</p> -->
    <p class="m-b-10"><span class="text-16">职业</span></p>
    <div v-for="(content,j) in tabArray_occupation" :key="j" style="padding-left: 12px;" class="m-b-10">
      <div class="inline text-darkgray" style="width: 90px;">{{ content.name }}</div>
      <div class="station-new-tag-content-single" :style="{'width': content.value + '%'}"></div>
      <div class="inline m-l-5">{{ content.value }}%</div>
    </div>

    <!-- <p class="text-14 text-darkgray" style="padding-left: 12px;margin-bottom: 10px;">行业</p> -->
    <p class="m-b-10"><span class="text-16">行业</span></p>
    <div v-for="(content,j) in tabArray_work" :key="'wordk'+j" style="padding-left: 12px;" class="m-b-10">
      <div class="inline text-darkgray" style="width: 90px;">{{ content.name }}</div>
      <div class="station-new-tag-content-single" :style="{'width': content.value + '%'}"></div>
      <div class="inline m-l-5">{{ content.value }}%</div>
    </div>

    <p class="m-b-10"><span class="text-16">业态分布</span></p>
    <Row>
      <i-col span="16">
        <div style="width:250px;height:250px;" :id="defaultOrderDetail? 'portrayFormatChart':'portrayFormatChart_station'"></div>
      </i-col>
      <i-col span="8">
        <div v-for="(item,i) in formatData" :key="i" class="station-chart-legend" :class="selectedFormat===item.id?'station-chart-legend-active':''" @click="handleClickFormat(item)">
          <div class="station-chart-legend-color" :style="{'background-color': reportColors[i % 9]}"></div>
          <div class="inline">{{item.name}}</div>
          <div class="right inline">{{ item.number }}个</div>
        </div>
      </i-col>
    </Row>

    <p class="m-b-10"><span class="text-16">配套分布</span></p>
    <Row>
      <i-col span="16">
        <div style="width:250px;height:250px;" :id="defaultOrderDetail?'portrayAssortedChart':'portrayAssortedChart_station'"></div>
      </i-col>
      <i-col span="8">
        <div v-for="(item,i) in assortedData" :key="i" class="station-chart-legend" :class="selectedAssorted===item.id?'station-chart-legend-active':''" @click="handleClickAssorted(item)">
          <div class="station-chart-legend-color" :style="{'background-color': reportColors[i % 9]}"></div>
          <div class="inline">{{item.name}}</div>
          <div class="right inline">{{ item.number }}个</div>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toNumber } from '@/utils/wnumb_own'
import {
  getSexLabel, getMarriageLabel,
  getAgeLabel, getIncomeLabel, getConsumeLabel, getEducationLabel, getTravelLabel,
  getOccupationLabel, getWorkLabel, countBusinessNumber
} from '@/api/dm/sun'

export default {
  props: {
    defaultOrderDetail: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      detailBean: {},

      tabColor1: ['#e84393', '#0984e3'],
      tabColor2: ['#122635', '#113857', '#0e4977', '#0d5790', '#0b6bb5', '#0984e3'],
      reportColors: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba277', '#fc8452', '#9a60b4', '#ea7ccc'],

      dataSourcePie1: null,
      dataSourcePie2: null,

      tabArray_sex: [], // 性别
      tabArray_marriage: [], // 婚姻
      tabArray_age: [], // 年龄
      tabArray_income: [], // 收入
      tabArray_consume: [], // 消费
      tabArray_education: [], // 教育
      tabArray_travel: [], // 出行
      tabArray_occupation: [], // 职业
      tabArray_work: [], // 工作行业

      formatData: [], // 业态数据
      selectedFormat: null,
      assortedData: [], // 配套数据
      selectedAssorted: null
    }
  },
  methods: {
    showDetail (params, type) {
      this.detailBean = params
      this.initTagData(params.stationIds)
      this.initChartsData(params.stationIds)
    },
    initTagData (svgStationId) {
      const queryModel = {
        personType: 1, // 人口类型(1.常驻;2:客流;)
        stationIds: svgStationId
      }

      // 获取性别标签
      getSexLabel(queryModel).then(res => {
        this.tabArray_sex = res
      })
      // 获取婚姻标签
      getMarriageLabel(queryModel).then(res => {
        this.tabArray_marriage = res
      })
      // 获取年龄标签
      getAgeLabel(queryModel).then(res => {
        this.tabArray_age = res
      })
      // 获取收入标签
      getIncomeLabel(queryModel).then(res => {
        this.tabArray_income = res
      })
      // 获取消费标签
      getConsumeLabel(queryModel).then(res => {
        this.tabArray_consume = res
      })
      // 获取教育标签
      getEducationLabel(queryModel).then(res => {
        this.tabArray_education = res
      })
      // 获取出行标签
      getTravelLabel(queryModel).then(res => {
        this.tabArray_travel = res
      })
      // 获取职业标签
      getOccupationLabel(queryModel).then(res => {
        this.tabArray_occupation = res
      })
      // 获取工作行业标签
      getWorkLabel(queryModel).then(res => {
        this.tabArray_work = res
      })
    },
    initChartsData (stationIds) {
      const queryModel = {
        industryType: 2, // 商业类型(1.配套;2:业态;)
        stationIds: stationIds
      }
      countBusinessNumber(queryModel).then(res => {
        this.formatData = res
        if (res.length > 0) {
          const seriesData = this.formatData.map(x => {
            return {
              name: x.name,
              value: x.number
            }
          })

          this.selectedFormat = this.formatData[0].id
          this.initFormatReport(seriesData, this.formatData[0].name, this.formatData[0].rate)
        }
      })

      const queryModel2 = {
        industryType: 1, // 商业类型(1.配套;2:业态;)
        stationIds: stationIds
      }
      countBusinessNumber(queryModel2).then(res => {
        this.assortedData = res
        if (res.length > 0) {
          const seriesData = this.assortedData.map(x => {
            return {
              name: x.name,
              value: x.number
            }
          })

          this.selectedAssorted = this.assortedData[0].id
          this.initAssortedReport(seriesData, this.assortedData[0].name, this.assortedData[0].rate)
        }
      })
    },
    initFormatReport (seriesData, selectedBunisessName, selectedBunisessRate) {
      if (this.dataSourcePie1 != null && this.dataSourcePie1 !== '' && this.dataSourcePie1 !== undefined) {
        this.dataSourcePie1.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie1 = echarts.init(document.getElementById(this.defaultOrderDetail ? 'portrayFormatChart' : 'portrayFormatChart_station'))
        this.loadFormatChartsData(seriesData, selectedBunisessName, selectedBunisessRate)
      })
    },
    loadFormatChartsData (seriesData, selectedBunisessName, selectedBunisessRate) {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        graphic: [
          {
            type: 'text',
            style: {
              x: 125,
              y: 100,
              text: selectedBunisessName,
              textAlign: 'center',
              fill: '#dcdee2',
              font: '14px Source Han Sans CN'
            }
          },
          {
            type: 'text',
            style: {
              x: 125,
              y: 130,
              text: selectedBunisessRate + '%',
              textAlign: 'center',
              fill: '#f8f8f9',
              font: '18px Source Han Sans CN'
            }
          }
        ],
        series: [
          {
            name: '业态类型',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      }

      this.dataSourcePie1.setOption(option, true)
    },
    initAssortedReport (seriesData, selectedBunisessName, selectedBunisessRate) {
      if (this.dataSourcePie2 != null && this.dataSourcePie2 !== '' && this.dataSourcePie2 !== undefined) {
        this.dataSourcePie2.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie2 = echarts.init(document.getElementById(this.defaultOrderDetail ? 'portrayAssortedChart' : 'portrayAssortedChart_station'))
        this.loadAssortedChartsData(seriesData, selectedBunisessName, selectedBunisessRate)
      })
    },
    loadAssortedChartsData (seriesData, selectedBunisessName, selectedBunisessRate) {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        graphic: [
          {
            type: 'text',
            style: {
              x: 125,
              y: 100,
              text: selectedBunisessName,
              textAlign: 'center',
              fill: '#dcdee2',
              font: '14px Source Han Sans CN'
            }
          },
          {
            type: 'text',
            style: {
              x: 125,
              y: 130,
              text: selectedBunisessRate + '%',
              textAlign: 'center',
              fill: '#f8f8f9',
              font: '18px Source Han Sans CN'
            }
          }
        ],
        series: [
          {
            name: '配套设施',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      }

      this.dataSourcePie2.setOption(option, true)
    },
    formatTagName (name, value) {
      if (value < 10) {
        return name.substr(0, 2) + '.'
      } else if (value < 13) {
        return name.substr(0, 3) + '.'
      } else if (value < 20) {
        return name.substr(0, 4) + '.'
      } else {
        return name
      }
    },
    formatTagValue (value) {
      if (value < 5) {
        return value.toString().substr(0, 1) + '.'
      } else if (value < 10) {
        return value.toString().substr(0, 3)
      } else {
        return value
      }
    },
    formatNumber (number) {
      return toNumber(number)
    },
    handleClickFormat (param) {
      this.selectedFormat = param.id
      // 更新报表中心的数据
      const seriesData = this.formatData.map(x => {
        return {
          name: x.name,
          value: x.number
        }
      })
      this.loadFormatChartsData(seriesData, param.name, param.rate)
    },
    handleClickAssorted (param) {
      this.selectedAssorted = param.id
      // 更新报表中心的数据
      const seriesData = this.assortedData.map(x => {
        return {
          name: x.name,
          value: x.number
        }
      })
      this.loadAssortedChartsData(seriesData, param.name, param.rate)
    }
  }
}
</script>
