import request from '@/utils/requestV2'
const qs = require('qs')

// 根据城市code，获取房价区间
export function getCityHousePriceRegion (data) {
  return request({
    url: '/ooh-dm/v1/dmp/building/getCityHousePriceRegion',
    method: 'post',
    data: qs.stringify(data)
  })
}
