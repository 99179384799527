<template>
    <Modal v-model="showUserLabel" width="800" title="设置受众标签" footer-hide :mask-closable="false">
      <Row v-for="(item,index) in labelData" :key="index">
        <i-col span="24">
          <p class="title p-t-5">{{item.labelType}}</p>
          <CheckboxGroup v-model="item.checkGroup">
              <Checkbox v-for="(subitem,j) in item.subLabels" :label="subitem.id" :key="j">{{subitem.name}}</Checkbox>
          </CheckboxGroup>
        </i-col>
      </Row>

      <p class="text-right">
        <Button class="btn-black m-r-10" size="small" @click="handelClearSetting">清除设置</Button>
        <Button type="success" class="m-r-5" size="small" @click="handleSaveSetting">保存设置</Button>
      </p>
    </Modal>
</template>

<script>
import { getFlowLabelNames } from '@/api/dmp/label'

export default {
  data () {
    return {
      showUserLabel: false,
      labelData: []
    }
  },
  methods: {
    showModal () {
      this.showUserLabel = true
      if (this.labelData.length === 0) {
        this.initData()
      }
    },
    initData () {
      // 获取受众标签
      getFlowLabelNames().then(res => {
        this.labelData = res.map(item => {
          item.checkGroup = []
          item.checkGroupName = []
          return item
        })
      })
    },
    handleSaveSetting () {
      const data = this.labelData.filter(x => x.checkGroup.length > 0)

      data.forEach(element => {
        element.checkGroupName = []
        element.checkGroup = element.checkGroup.sort()

        element.checkGroup.forEach(item => {
          element.checkGroupName.push(element.subLabels.find(x => x.id === item).name)
        })
      })

      this.$emit('handleSaveUserLabel', data)
      this.showUserLabel = false
    },
    handelClearSetting () {
      this.$emit('handleSaveUserLabel', [])
      this.showUserLabel = false
    }
  }
}
</script>
