import request from '@/utils/requestV2'
// const qs = require('qs')

// 通过站点id获取商家集合分页
export function getFlowLabelNames () {
  return request({
    url: '/ooh-dm/v1/label/getFlowLabelNames',
    method: 'get'
  })
}
